<template>
   <section :class="classes" v-if="listaMarcas != null && listaMarcas.length > 0">
      <div class="container">
         <div class="owl-carousel one-show t-nav" :class="view == 'Home' ? 'brand__slider' : 'brand__logo__slider'">
            <marca v-for="(marca, index) in listaMarcas" :key="index" :marca="marca" :view="view" />
         </div>
      </div>
   </section>
</template>

<script>

import Vue from 'vue'
import $ from 'jquery'
import { mapState } from 'vuex'
import marca from '@/components/home/Marca.vue'

export default {
   name: 'CarouselLogoMarcas',
   props: ['classes', 'view'],
   data: function () {
      return {
         listaMarcas: []
      }
   },
   computed: {
		... mapState({
			clienteData: state => state.clienteData,
			urlRest: state => state.urlRest
		})
	},
   watch: {
      listaMarcas : function (newVal, oldVal) {
         if (newVal != null && newVal.length > 0 && JSON.stringify(oldVal) != JSON.stringify(newVal)) {
            if (this.view == 'Home') {
               this.configCarousel()
            } else { // brand__logo__slider (SejaRevendedor ou TrabalheConosco)
               this.configCarousel2()
            }
         }
      },
      'clienteData.marcas' : function (val) {
         if (val != null && val.length > 0 && this.view == 'Home') {
            this.listaMarcas = val;
         }
      }
   },
   components: {
      marca
   },
   methods: {
      getMarcas : function () {
         this.$axios({
            method: 'get',
            url: this.urlRest +'site/getMarcas'

         }).then(response => this.listaMarcas = response.data);
      },
      configCarousel : function () {
         if (this.listaMarcas != null && this.listaMarcas.length > 0) {
            Vue.nextTick(function() {
               $('.brand__slider').owlCarousel({
                  center: true,
                  margin: 5,
                  items: 6,
                  navText:['<button><i class="fal fa-angle-left"></i></button>','<button><i class="fal fa-angle-right"></i></button>'],
                  rewind: true,
                  nav: true,
                  autoplay: true,
                  mouseDrag: false,
                  touchDrag: false,
                  autoplayTimeout: 5000,
                  startPosition: Math.ceil(this.listaMarcas.length / 2) - 1,
                  responsive:{
                     0:{
                        items:1
                     },
                     576:{
                        items:2
                     },
                     767:{
                        items:2
                     },
                     992:{
                        items:4
                     },
                     1200:{
                        items:6
                     },
                     1600:{
                        items:7
                     }
                  }
               });
            }.bind(this));
         }
      },
      configCarousel2 : function () {
         if (this.listaMarcas != null && this.listaMarcas.length > 0) {
            Vue.nextTick(function() {
               $('.brand__logo__slider').owlCarousel({
                  margin: 5,
                  loop: true,
                  autoplay: true,
                  autoplayTimeout: 7500,
                  responsive:{
                     0:{
                        items:2
                     },
                     576:{
                        items:3
                     },
                     767:{
                        items:3
                     },
                     992:{
                        items:4
                     },
                     1200:{
                        items:4
                     },
                     1600:{
                        items:5
                     }
                  }
               });
            }.bind(this));
         }
      }
   },
   mounted() {
      if (this.view == 'Home') {
         this.listaMarcas = this.clienteData.marcas;
      } else { // brand__logo__slider (SejaRevendedor ou TrabalheConosco)
         this.getMarcas()
      }
   }
}

</script>