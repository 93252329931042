<template>
   <div class="brand__item" ref="itemDesc" :class="view != 'Home' ? 'h-auto' : 'pt-40'">
      <div class="row m-0">
         <div class="col-12">
            <img :src="marca.foto == null ? '' : marca.foto" class="brand__image" :alt="marca.nome" :style="view != 'Home' ? 'opacity: 0.2; filter: grayscale(1);' : 'cursor: pointer;'" 
               @click="pesquisar">
         </div>
      </div>
      <div class="brand__text py-4 mx-auto" v-if="view == 'Home'" :style="{left: positionLeft +'px', right: positionLeft +'px'}">
         <p class="limitador-2 text-center">{{ marca.descricao }}</p>
         <div class="justify-content-center d-flex">
            <hr class="col bg-theme d-none d-lg-inline">
            <div class="w-max-content px-2 color-theme pt-1 weight-600 font-14">
               <a href="javascript:;" @click="pesquisar" class="d-none d-md-inline">Conheça a linha completa de equipamentos <i class="fas fa-plus font-12 ms-1"></i></a>
               <a href="javascript:;" @click="pesquisar" class="d-inline d-md-none">Equipamentos <i class="fas fa-plus font-12 ms-1"></i></a>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import Vue from 'vue'
import $ from 'jquery'

export default {
   name: 'Marca',
   props: ['marca', 'view'],
   data: function () {
      return {
         positionLeft: 0
      }
   },
   methods: {
      pesquisar : function () {
         if (this.view == 'Home') {
            this.$store.dispatch('setPesquisa', {'categoria': '', 'subcategoria': '', 'tipoPesquisa': 'Marca', 'valor': this.marca.nome})
         }
      }
   },
   mounted() {
      Vue.nextTick(function() {
         try {
            this.positionLeft = Math.abs($('.brand__slider .owl-item.center').offset().left - 125) * -1
         } catch {
            console.log('Owl item not found')
         }
         
         $(window).on("resize", () => {
            try {
               this.positionLeft = Math.abs($('.brand__slider .owl-item.center').offset().left - 125) * -1
            } catch {
               console.log('Owl item not found')
            }
         });
      }.bind(this));
   }
}

</script>

<style scoped>

img {
	aspect-ratio: 3 / 1;
	object-fit: contain;
}

hr {
   max-width: 50%;
}

</style>